.color4 .navigation .main-nav ul li a:after {background-color: #DE3F0F;}
.color4  .navigation .main-nav .icon-shortcut {background-color: #E5B200;}
.color4 a {color: #DE3F0F;}
.color4 a:active, .color4 a:focus, .color4 a:hover {color: #DE3F0F;}
.color4 a:before {background-color: #DE3F0F;}
.color4 .navigation a:before,
.color4 .feature-box a:before,
.color4 .thumbnail__image a:before{background-color: transparent;}
.color4 .thumbnail__title { background-color: #DE3F0F; }

.navigation a {color: black;}
.color4  a.link.underline {border-bottom: 3px solid #DE3F0F;}
.color4 address a:hover, .color4 address a:focus, .color4 address a:active {color: #DE3F0F;}
.color4 address .info i {color: #DE3F0F;}
.color4 blockquote footer, .color4 blockquote footer p, .color4 blockquote strong {color: #DE3F0F;}
.color4 h2 {color: #DE3F0F;}
.color4  .bg.bg-color-default {background-color: #DE3F0F;}
.color4  .bg.bg-color-default-darker {background-color: #2b8938;}
.color4 .blog-post .meta .tags .tag {border: 2px solid #DE3F0F;}
.color4 .calendar .calendar-month-navigation > span i {background-color: #DE3F0F;}
.color4 .feature-box > i {color: #DE3F0F;}
.color4 #footer .right .bg-right {background-color: #00859A;}
.color4 .modal.workshop-detail .modal-header .time i {color: #DE3F0F;}
.color4 .numbers .number figure {color: #DE3F0F;}
.color4 .pagination li.active a, .color4 .pagination li.active a:hover {background-color: #DE3F0F;}
.color4 .pagination li.active a, .color4 .pagination li.active a:hover {background-color: #DE3F0F;}
.color4 .color4 .pricing ul li i {color: #DE3F0F;}
.color4 .pricing .price-box.promoted {background-color: #DE3F0F;}
.color4 .sidebar h3 {color: #DE3F0F;}
.color4 .steps .step figure .bar {background-color: #DE3F0F;}
.color4 .steps .step h3 {color: #DE3F0F;}
.color4 .workshop-list .workshop .date-info .time i {color: #DE3F0F;}
.color4 .workshop-list .workshop h4 {color: #DE3F0F;}
.color4 .btn.btn-default {
  border: 2px solid #DE3F0F;
  color: #DE3F0F;
}
.color4 .btn.btn-default:hover, .color4 .btn.btn-default:active, .color4 .btn.btn-default:focus {
  border: 2px solid #DE3F0F;
  background-color: #DE3F0F;
}
.color4 .btn.btn-default.btn-white:hover, .color4 .btn.btn-default.btn-white:active, .color4 .btn.btn-default.btn-white:focus {color: #DE3F0F;}
.color4 .btn.btn-default.btn-black:hover, .color4 .btn.btn-default.btn-black:active, .color4 .btn.btn-default.btn-black:focus {background-color: #DE3F0F;}
.color4 .btn.btn-primary {background-color: #DE3F0F;}
.color4 .btn.btn-primary.btn-white {color: #DE3F0F;}
.color4 .btn.btn-primary.btn-white:hover, .color4 .btn.btn-primary.btn-white:active, .color4 .btn.btn-primary.btn-white:focus {color: #DE3F0F;}
.color4 .btn.time-radio.active {background-color: #DE3F0F;}
.color4 blockquote p:before {color: #DE3F0F;}
.color4 footer a {color: #000000;}
.color4 .navigation .main-nav .icon-shortcut:hover {background-color: #E5B200;}