/*------------------------------------------------------------------
Project:	Site web Blandine Henaud
Version:	1.0
Last change:	10.06.2022
Author:	Samuel SORIN <ssorin@dwdlc.com>

[Table of contents]

1. Header
2. General Elements Styling
3. Home
  a. hero / jumbotron
  b. feature section
  c. about section
7. Responsive

[Color codes]
[Custom Color codes respectively in color_1.css, color_2.css, ..., color_5.css ]
color_1.css turquoise : #00859A
color_2.css jaune : #E5B200
color_3.css rouge : #AA1538
color_4.css orange : #DE3F0F
color_5.css vert : #71A095
Texte et menu noir #000000 à 80%
Over menu #000000 à 100%

[Typography]

Body copy:		'Lato', sans-serif; 16px;
Headers:		'Lato', sans-serif;

-------------------------------------------------------------------*/


.block__no-padding {
  padding-top: 0;
  padding-bottom: 0;
}

/*1. Header*/
.brand {
  width: 330px;
  height: 52px;
}

.navigation .main-nav {
    padding-bottom: 20px;
}

.navigation .secondary-nav {
  font-size: 11px;
}

.navigation .main-nav ul li.active ul a,
.navigation .main-nav ul li:hover ul a{
  font-weight: normal;
  opacity: 0.6;
}

.navigation .main-nav ul li.active > a,
.navigation .main-nav ul li:hover > a{
  font-weight: bold;
  pacity: 1;
}

/* SUB MENU */
.navigation .main-nav ul ul {
  display: none;
  position: absolute;
  margin-top: 0;
  padding-top: 20px;
  margin-left: -25px;
}

.navigation .main-nav ul li > ul {
  z-index: 2;
}

.navigation .main-nav ul ul li {
  float: none;
  width: 201px;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  background-color: #faf9f6;
  margin: 0;
  box-shadow: 0 5px 6px 1px rgba(0, 0, 0, 0.15);
}
.navigation .main-nav ul ul li a {
  display: block;
  width: 100%;
  height: 40px;
  margin: 0;
  padding-left: 15px;
}

.navigation .main-nav ul ul li a:after {background-color: transparent !important}

.navigation .main-nav ul li > ul li:hover > a:after {
    visibility: visible;
    content: "";
    float: right;
    width: 12px;
    height: 12px;
    position: relative;
    background-color: transparent !important;
    top: 0;
    margin-right: 15px;
    /*font-size: 16px;*/
    font-size: 1em;
    font-weight: 700;
    text-transform: uppercase;
}

.navigation .main-nav ul li ul li a,
.navigation .main-nav ul li.active ul li a {
  font-weight: normal;
}

.navigation .main-nav ul li > ul li.active > a,
.navigation .main-nav ul li > ul li > a:hover  {
  font-weight: bold;
}

/*.navigation .main-nav ul li.active ul li a:after {*/
/*  display: none;*/
/*}*/

/*.navigation .main-nav ul li.active > ul,*/
.navigation .main-nav ul li:hover > ul {
  display: block;
}

.navigation .main-nav ul li > ul li > ul{
    left: 225px;
    top: 0;
    z-index: -1;
}

.navigation .main-nav ul li > ul li > ul li a:after,
.navigation .main-nav ul li > ul li > ul li:hover a:after {
  content: "";
}

.navigation .main-nav ul li a:last-child {
  margin-right: 15px;
}
.navigation .main-nav ul > li:last-child a {
  margin-right: 0;
}

/* wysiwyg*/
.text-content h2 {
  margin-top: 45px;
  margin-bottom: 30px;
}
.text-content h2:first-child {
  margin-top: 0;
}

.text-content h3 {
  font-size: 1.2rem;
  margin-top: 25px;
  margin-bottom: 7px;
}
.text-content h3:first-child {
  margin-top: 0;
}

.text-content h4 {
  font-size: 1.2rem;
}

.text-content ul {
  padding-left: 1rem;
}
.text-content li {
  opacity: 0.7;
}

/*2. Genral Elements Styling*/
.heading h1 {
  margin-top: 10px;
  margin-bottom: 8px;
  text-transform: uppercase;
}
.heading h1:first-letter {
  text-transform: uppercase;
}

/*a*/
/*{*/
/*    position: relative;*/
/*}*/
.secondary-nav span a:hover{color:#ffffff}
.jumbotron a:before {color: transparent}
a {
  position: relative;
}
a:before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -4px;
  left: 0;
  background-color: #37b048;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.page-wrapper a:hover:before {
  visibility: visible;
  width: 90%;
}

.picture {
  margin-bottom: 30px;
}
.center .picture {
  margin-top: 15px;
}
.picture.text-left{
  float: left;
  margin-right: 30px;
}
.picture.text-right{
  float: right;
  margin-left: 30px;
}

iframe {
  max-width: 100%;
}

.ml-40 {
  margin-left: 39px;
}

/* 3. home */

/* -- 3a. hero / jumbotron */
.jumbotron {
  margin-bottom: 0;
  padding-top: 185px;
}
.jumbotron {
  height: 665px;
  background-size: cover;
  background-repeat: no-repeat;
}
.jumbotron .main-sentence {
  max-width: 680px;
  text-shadow: 0 0 38px rgba(0, 0, 0, 0.31);
  color: #ffffff;
  /*font-size: 61px;*/
  font-size: 2.8125em;
  font-weight: 700;
}
.jumbotron .secondary-sentence {
  width: 360px;
  height: 18px;
  opacity: 0.6;
  color: #000000;
  /*font-size: 18px;*/
  font-size: 1.125em;
  font-weight: 400;
}

/* -- 3b. feature section */
.feature-box {
  margin: 40px 0;
}

/* -- 3c. about section */
.home__about {
  background-image: url(/static/core/img/home-bg.png);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 45px;
}
.home__about h2 {
  /*font-size: 36px;*/
  font-size: 2.25em;
  font-weight: 300;
  color: #71A095;
}
.home__about h3 {
  /*font-size: 24px;*/
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0;
}
.home__about h4 {
  margin-bottom: 1.5em;
}
.home__about strong {
  color: #71A095;
  margin-bottom: 1.5em;
  font-size: 20px;
}
.home__about a {color: #00859A;}

/* 4. footer */
footer {
  margin-top: 60px;
}

footer p a {
  opacity: 0.7;
  color: #000000;
}


/* list-links */
ul.list-links {
  color: #d9d9d9;
  margin-left: 2px;
}
ul.list-links li.active > a {
    font-weight: bold;
}


/* thumbnail */
.thumbnail {
  height: 500px;
  margin-bottom: 40px;
  padding: 0;
  line-height: 1.42857143;
  background-color: #fff;
  border: 0;
  border-radius: 0;
}
.thumbnail__image {
  padding: 0;
  margin: 0;
  max-height: 180px;
  overflow: hidden;
}
.thumbnail__caption {
  margin: 0;
  background: #faf9f6;
  padding-bottom: 40px;
}
.thumbnail__title {
  padding: 0 30px;
  height: 68px;
  background-color: #e3b120;
  line-height: 70px;
  vertical-align: middle;
}
.thumbnail__content {
  padding: 10px 30px;
}
.thumbnail__caption h3 {
  opacity: 0.95;
  margin: 0;
  color: #ffffff;
  line-height: 70px;
  text-transform: uppercase;
}


/* blockquote */
blockquote {
  text-align: center;
  padding-top: 65px;
  padding-bottom: 75px;
}
blockquote p {
  opacity: 0.6;
  color: #000000;
  /*font-size: 24px;*/
  font-size: 1.5em;
  font-weight: 400;
  max-width: 730px;
}
blockquote p:before {
  content: "“";
  position: relative;
  display: inline-block;
  top: 20px;
  margin-top: -50px;
  width: 32px;
  height: 26px;
  font-family: Arial;
  /*font-size: 83px;*/
  font-size: 5.1875em;
  font-weight: 400;
  text-transform: uppercase;
}
blockquote strong {
  display: block;
  /*font-size: 12px;*/
  font-size: .75em;
  line-height: 1.42857143;
  font-weight: 700;
  text-transform: uppercase;
  padding-top: 15px;
}
blockquote strong:before {
    content: '\2014 \00A0';
}

/* breadcrumbs */
ul.breadcrumbs {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.breadcrumbs li {
  display: inline-block;
  margin: 0;
  margin-bottom: 15px;
}
ul.breadcrumbs li,
ul.breadcrumbs li a {
  color: rgba(0, 0, 0, 0.4);
  text-transform: capitalize;
}
ul.breadcrumbs li a {
  text-decoration: underline;
}


address {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8;
}

.modal-backdrop.fade { opacity: 0.8}
.modal-open .modal { opacity: 1;}

/* responsiv */
@media (max-width: 767px) {
  .navigation .main-nav ul li > ul {
    display: block;
    position: relative;
    right: 0;
    width: calc(100% - 5px);
    padding: 0;
    margin: 0 0 0 5px;
  }

  .navigation .main-nav nav ul li > ul li a {
    color: #000;
  }

  .navigation .main-nav ul>li:last-child a {
    margin-right: 15px;
  }

  .jumbotron {
    height: auto;
    margin-bottom: 0;
    padding-top: 20px;
}

  .jumbotron .main-sentence {
    font-size: 1.4em;
  }
}
