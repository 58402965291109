.color3 .navigation .main-nav ul li a:after {background-color: #AA1538;}
.color3  .navigation .main-nav .icon-shortcut {background-color: #E5B200;}
.color3 a {color: #AA1538;}
.color3 a:before {background-color: #AA1538;}
.color3 .navigation a:before,
.color3 .feature-box a:before,
.color3 .jumbotron a:before,
.color3 .thumbnail__image a:before{background-color: transparent;}
.color3 .thumbnail__title { background-color: #AA1538; }

.color3 a:active, .color3 a:focus, .color3 a:hover {color: #AA1538;}
.navigation a {color: black;}
.color3  a.link.underline {border-bottom: 3px solid #AA1538;}
.color3 address a:hover, .color3 address a:focus, .color3 address a:active {color: #AA1538;}
.color3 address .info i {color: #AA1538;}
.color3 blockquote footer, .color3 blockquote footer p, .color3 blockquote strong {color: #AA1538;}
.color3 h2 {color: #AA1538;}
.color3  .bg.bg-color-default {background-color: #AA1538;}
.color3  .bg.bg-color-default-darker {background-color: #2b8938;}
.color3 .blog-post .meta .tags .tag {border: 2px solid #AA1538;}
.color3 .calendar .calendar-month-navigation > span i {background-color: #AA1538;}
.color3 .feature-box > i {color: #AA1538;}
.color3 #footer .right .bg-right {background-color: #00859A;}
.color3 .modal.workshop-detail .modal-header .time i {color: #AA1538;}
.color3 .numbers .number figure {color: #AA1538;}
.color3 .pagination li.active a, .color3 .pagination li.active a:hover {background-color: #AA1538;}
.color3 .pagination li.active a, .color3 .pagination li.active a:hover {background-color: #AA1538;}
.color3 .color3 .pricing ul li i {color: #AA1538;}
.color3 .pricing .price-box.promoted {background-color: #AA1538;}
.color3 .sidebar h3 {color: #AA1538;}
.color3 .steps .step figure .bar {background-color: #AA1538;}
.color3 .steps .step h3 {color: #AA1538;}
.color3 .workshop-list .workshop .date-info .time i {color: #AA1538;}
.color3 .workshop-list .workshop h4 {color: #AA1538;}
.color3 .btn.btn-default {
  border: 2px solid #AA1538;
  color: #AA1538;
}
.color3 .btn.btn-default:hover, .color3 .btn.btn-default:active, .color3 .btn.btn-default:focus {
  border: 2px solid #AA1538;
  background-color: #AA1538;
}
.color3 .btn.btn-default.btn-white:hover, .color3 .btn.btn-default.btn-white:active, .color3 .btn.btn-default.btn-white:focus {color: #AA1538;}
.color3 .btn.btn-default.btn-black:hover, .color3 .btn.btn-default.btn-black:active, .color3 .btn.btn-default.btn-black:focus {background-color: #AA1538;}
.color3 .btn.btn-primary {background-color: #AA1538;}
.color3 .btn.btn-primary.btn-white {color: #AA1538;}
.color3 .btn.btn-primary.btn-white:hover, .color3 .btn.btn-primary.btn-white:active, .color3 .btn.btn-primary.btn-white:focus {color: #AA1538;}
.color3 .btn.time-radio.active {background-color: #AA1538;}
.color3 blockquote p:before {color: #AA1538;}
.color3 footer a {color: #000000;}
.color3 .navigation .main-nav .icon-shortcut:hover {background-color: #E5B200;}