.color2 .navigation .main-nav ul li a:after {background-color: #E5B200;}
.color2 .navigation .main-nav .icon-shortcut {background-color: #E5B200;}
.color2 a {color: #E5B200;}
.color2 a:before {background-color: #E5B200;}
.color2 .navigation a:before,
.color2 .feature-box a:before,
.color2 .thumbnail__image a:before {background-color: transparent;}
.color1 .thumbnail__title { background-color: #e3b120; }

.color2 a:active, .color2 a:focus, .color2 a:hover {color: #E5B200;}
.navigation a {color: black;}
.color2  a.link.underline {border-bottom: 3px solid #E5B200;}
.color2 address a:hover, .color2 address a:focus, .color2 address a:active {color: #E5B200;}
.color2 address .info i {color: #E5B200;}
.color2 blockquote footer, .color2 blockquote footer p, .color2 blockquote strong {color: #E5B200;}
.color2 h2 {color: #E5B200;}
.color2  .bg.bg-color-default {background-color: #E5B200;}
.color2  .bg.bg-color-default-darker {background-color: #2b8938;}
.color2 .blog-post .meta .tags .tag {border: 2px solid #E5B200;}
.color2 .calendar .calendar-month-navigation > span i {background-color: #E5B200;}
.color2 .feature-box > i {color: #E5B200;}
.color2 #footer .right .bg-right {background-color: #00859A;}
.color2 .modal.workshop-detail .modal-header .time i {color: #E5B200;}
.color2 .numbers .number figure {color: #E5B200;}
.color2 .pagination li.active a, .color2 .pagination li.active a:hover {background-color: #E5B200;}
.color2 .pagination li.active a, .color2 .pagination li.active a:hover {background-color: #E5B200;}
.color2 .color2 .pricing ul li i {color: #E5B200;}
.color2 .pricing .price-box.promoted {background-color: #E5B200;}
.color2 .sidebar h3 {color: #E5B200;}
.color2 .steps .step figure .bar {background-color: #E5B200;}
.color2 .steps .step h3 {color: #E5B200;}
.color2 .workshop-list .workshop .date-info .time i {color: #E5B200;}
.color2 .workshop-list .workshop h4 {color: #E5B200;}
.color2 .btn.btn-default {
  border: 2px solid #E5B200;
  color: #E5B200;
}
.color2 .btn.btn-default:hover, .color2 .btn.btn-default:active, .color2 .btn.btn-default:focus {
  border: 2px solid #E5B200;
  background-color: #E5B200;
}
.color2 .btn.btn-default.btn-white:hover, .color2 .btn.btn-default.btn-white:active, .color2 .btn.btn-default.btn-white:focus {color: #E5B200;}
.color2 .btn.btn-default.btn-black:hover, .color2 .btn.btn-default.btn-black:active, .color2 .btn.btn-default.btn-black:focus {background-color: #E5B200;}
.color2 .btn.btn-primary {background-color: #E5B200;}
.color2 .btn.btn-primary.btn-white {color: #E5B200;}
.color2 .btn.btn-primary.btn-white:hover, .color2 .btn.btn-primary.btn-white:active, .color2 .btn.btn-primary.btn-white:focus {color: #E5B200;}
.color2 .btn.time-radio.active {background-color: #E5B200;}
.color2 blockquote p:before {color: #E5B200;}
.color2 footer a {color: #000000;}
.color2 .navigation .main-nav .icon-shortcut:hover {background-color: #E5B200;}