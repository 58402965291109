.navigation .main-nav ul li a:after {background-color: #37b048;}
.navigation .main-nav .icon-shortcut {background-color: #37b048;}
a {color: #37b048;}
a.link.underline {border-bottom: 3px solid #37b048;}
address a:hover, address a:focus, address a:active {color: #37b048;}
address .info i {color: #37b048;}
blockquote footer, blockquote footer p, blockquote strong {color: #37b048;}
h2 {color: #37b048;}
.bg.bg-color-default {background-color: #37b048;}
.bg.bg-color-default-darker {background-color: #2b8938;}
.blog-post .meta .tags .tag {border: 2px solid #37b048;}
.calendar .calendar-month-navigation > span i {background-color: #37b048;}
.feature-box > i {color: #37b048;}
#footer .right .bg-right {background-color: #37b048;}
.modal.workshop-detail .modal-header .time i {color: #37b048;}
.numbers .number figure {color: #37b048;}
.pagination li.active a, .pagination li.active a:hover {background-color: #37b048;}
.pagination li.active a, .pagination li.active a:hover {background-color: #37b048;}
.pricing ul li i {color: #37b048;}
.pricing .price-box.promoted {background-color: #37b048;}
.sidebar h3 {color: #37b048;}
.steps .step figure .bar {background-color: #37b048;}
.steps .step h3 {color: #37b048;}
.workshop-list .workshop .date-info .time i {color: #37b048;}
.workshop-list .workshop h4 {color: #37b048;}
.btn.btn-default {
  border: 2px solid #37b048;
  color: #37b048;
}
.btn.btn-default:hover, .btn.btn-default:active, .btn.btn-default:focus {
  border: 2px solid #37b048;
  background-color: #37b048;
}
.btn.btn-default.btn-white:hover, .btn.btn-default.btn-white:active, .btn.btn-default.btn-white:focus {color: #37b048;}
.btn.btn-default.btn-black:hover, .btn.btn-default.btn-black:active, .btn.btn-default.btn-black:focus {background-color: #37b048;}
.btn.btn-primary {background-color: #37b048;}
.btn.btn-primary.btn-white {color: #37b048;}
.btn.btn-primary.btn-white:hover, .btn.btn-primary.btn-white:active, .btn.btn-primary.btn-white:focus {color: #37b048;}
.btn.time-radio.active {background-color: #37b048;}



.navigation .secondary-nav {
  background-color: #00859A;
  color: #fff;
}
.navigation .secondary-nav a, .navigation .secondary-nav span {
  color: #fff;
}

#footer .right .bg-right {
  background-color: #00859A;
}

img .cls-1 {
  color: #37b048;
  background-color: transparent
}
