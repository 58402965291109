.color1 .navigation .main-nav ul li a:after {background-color: #00859A;}
.color1 .navigation .main-nav .icon-shortcut {background-color: #E5B200;}
.color1 a {color: #00859A;}
.color1 a:before {background-color: #00859A;}
.color1 .navigation a:before,
.color1 .feature-box a:before,
.color1 .jumbotron a:before,
.color1 .thumbnail__image a:before {background-color: transparent;}
.color1 .thumbnail__title { background-color: #00859A; }

.color1 a:active, .color1 a:focus, .color1 a:hover {color: #00859A;}
.navigation a {color: black;}
a:before {background-color: #00859A;}

.color1  a.link.underline {border-bottom: 3px solid #00859A;}
.color1 address a:hover, .color1 address a:focus, .color1 address a:active {color: #00859A;}
.color1 address .info i {color: #00859A;}
.color1 blockquote footer, .color1 blockquote footer p, .color1 blockquote strong {color: #00859A;}
.color1 h2 {color: #00859A;}
.color1  .bg.bg-color-default {background-color: #00859A;}
.color1  .bg.bg-color-default-darker {background-color: #2b8938;}
.color1 .blog-post .meta .tags .tag {border: 2px solid #00859A;}
.color1 .calendar .calendar-month-navigation > span i {background-color: #00859A;}
.color1 .feature-box > i {color: #00859A;}
.color1 #footer .right .bg-right {background-color: #00859A;}
.color1 .modal.workshop-detail .modal-header .time i {color: #00859A;}
.color1 .numbers .number figure {color: #00859A;}
.color1 .pagination li.active a, .color1 .pagination li.active a:hover {background-color: #00859A;}
.color1 .pagination li.active a, .color1 .pagination li.active a:hover {background-color: #00859A;}
.color1 .color1 .pricing ul li i {color: #00859A;}
.color1 .pricing .price-box.promoted {background-color: #00859A;}
.color1 .sidebar h3 {color: #00859A;}
.color1 .steps .step figure .bar {background-color: #00859A;}
.color1 .steps .step h3 {color: #00859A;}
.color1 .workshop-list .workshop .date-info .time i {color: #00859A;}
.color1 .workshop-list .workshop h4 {color: #00859A;}
.color1 .btn.btn-default {
  border: 2px solid #00859A;
  color: #00859A;
}
.color1 .btn.btn-default:hover, .color1 .btn.btn-default:active, .color1 .btn.btn-default:focus {
  border: 2px solid #00859A;
  background-color: #00859A;
}
.color1 .btn.btn-default.btn-white:hover, .color1 .btn.btn-default.btn-white:active, .color1 .btn.btn-default.btn-white:focus {color: #00859A;}
.color1 .btn.btn-default.btn-black:hover, .color1 .btn.btn-default.btn-black:active, .color1 .btn.btn-default.btn-black:focus {background-color: #00859A;}
.color1 .btn.btn-primary {background-color: #00859A;}
.color1 .btn.btn-primary.btn-white {color: #00859A;}
.color1 .btn.btn-primary.btn-white:hover, .color1 .btn.btn-primary.btn-white:active, .color1 .btn.btn-primary.btn-white:focus {color: #00859A;}
.color1 .btn.time-radio.active {background-color: #00859A;}
.color1 blockquote p:before {color: #00859A;}
.color1 footer a {color: #000000;}
.color1 .navigation .main-nav .icon-shortcut:hover {background-color: #E5B200;}